<template>
  <mp-link
    class="card"
    :data-static-desktop="numberOfItems < 4 ? 'true' : 'false'"
    :to="$u(link)"
  >
    <ResponsiveImage
      :mobile-src="image?.filename"
      :desktop-src="image?.filename"
      mobile-display-size="80vw"
      desktop-display-size="40vw"
      alt="image"
      class="card-image"
    />

    <div class="card-content">
      <span class="card-title">{{ title }}</span>
      <CircleArrow class="card-icon" />
    </div>
  </mp-link>
</template>

<script>
import CircleArrow from '~/assets/icons/circle-arrow.svg'
export default {
  components: {
    CircleArrow
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    numberOfItems: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss">
.card {
  position: relative;
  min-width: 66vw;
  aspect-ratio: 3 / 4.25;
  overflow: hidden;

  .card-image {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    filter: hsla(0, 0%, 87%, 1);

    picture {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .card-content {
    position: relative;
    z-index: 10;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
  }

  .card-title {
    z-index: 10;
    font-size: 22px;
    font-family: "StrongerFuturaEF";
    font-weight: 400;
    color: white;
    letter-spacing: -0.03em;
  }

  .card-icon {
    width: 22px;
    height: 22px;
    fill: white;
    margin-left: 10px;
  }
}

@media screen and (min-width: $tablet) {
  .card[data-static-desktop="true"] {
    min-width: calc(33% - 12px);
  }

  .card {
    min-width: 29vw;

    .card-content {
      padding: 32px 24px;
    }

    .card-icon {
      width: 35px;
      height: 35px;
    }

    .card-title {
      font-size: 30px;
      line-height: 33px;
    }
  }
}
</style>
