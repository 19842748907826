<template>
  <div class="slider">
    <h3 class="slider-title">{{ headline }}</h3>
    <div class="slider-container" ref="sliderContainer">
      <CategoryItem
        v-for="category in categories"
        :key="category?.id"
        :number-of-items="categories?.length"
        :image="category?.image"
        :title="category?.title"
        :link="category?.link"
      />
    </div>
    <div
      class="slider-outerscrollbar"
      :class="{ hidden : categories?.length <= 3 }"
      ref="sliderOuterscrollbar"
      @click="scrollToClick"
    >
      <div class="slider-scrollbar" ref="sliderScrollbar" />
    </div>
  </div>
</template>

<script>
import CategoryItem from './CategoryItem.vue'

export default {
  name: 'CategorySlider',
  components: {
    CategoryItem
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    headline: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.$refs.sliderContainer.addEventListener('scroll', this.updateScrollbar)
  },
  methods: {
    updateScrollbar () {
      const { $refs: { sliderContainer, sliderScrollbar } } = this

      if (!sliderContainer || !sliderScrollbar) { return }
      const scrollWidth = sliderContainer.scrollWidth - sliderContainer.clientWidth
      const scrollPercent = sliderContainer.scrollLeft / scrollWidth
      sliderScrollbar.style.width = `${scrollPercent * 100}%`
    },
    scrollToClick (event) {
      const { $refs: { sliderContainer, sliderOuterscrollbar } } = this

      if (!sliderContainer || !sliderOuterscrollbar) { return }
      const clickPosition = event.clientX - sliderOuterscrollbar.getBoundingClientRect().left
      const clickPercent = clickPosition / sliderOuterscrollbar.offsetWidth
      const scrollWidth = sliderContainer.scrollWidth - sliderContainer.clientWidth
      sliderContainer.scrollLeft = clickPercent * scrollWidth
    }
  }
}
</script>

<style scoped lang="scss">
.slider {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 0px 16px 16px;

  .slider-container {
    display: flex;
    overflow-x: scroll;
    gap: 16px;
    padding-right: 24px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */

    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }

  .slider-outerscrollbar {
    cursor: pointer;
    max-width: 300px;
    display: flex;
    background: #CCCCCC;
    align-self: center;
    width: 100%;
    margin-top: 16px;
  }

  .slider-scrollbar {
    height: 3px;
    background: black;
  }

  .slider-title {
    font-size: 32px;
    line-height: 1;
    font-family: "StrongerFuturaEF";
    font-weight: 400;
    letter-spacing: -0.03em;
  }
}

@media screen and (min-width: $tablet) {
  .slider {
    padding: 32px 0px 32px 32px;
    gap: 24px;

    .slider-container {
      gap: 24px;
    }

    .hidden {
      display: none;
    }

    .slider-title {
      font-size: 40px;
    }
  }
}
</style>
